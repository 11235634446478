
import './App.css';
import Navbar from './components/Navbar';
function App() {
  return (
    <Navbar/>

  );
}

export default App;
