import React from 'react';
import '../css/Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className="nav_container" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'}}>
      <nav>
        <ul className="drop-down-menu">
          <li><Link to="#">NEW IN</Link></li>
          <li>
            <Link to="#">SALE限時優惠</Link>
            <ul>
              <li><Link to="#">【加碼】四年一度‼️服飾下殺29折</Link></li>
              <li><Link to="#">美到有春🌺限定組合 $888起</Link></li>
              <li><Link to="#">女王限定💕多用盤$380</Link></li>
              <li><Link to="#">熱銷精選💖任2件38折%OFF</Link></li>
            </ul>
          </li>
          <li><Link to="#">髮品 HAIR CARE</Link></li>
          <li><Link to="#">彩妝 MAKEUP</Link></li>
          <li><Link to="#">保養 SKINCARE</Link></li>
          <li><Link to="#">機能修飾衣著 CURVE</Link></li>
          <li><Link to="#">養髮研究所</Link></li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
